@font-face {
  font-family: 'AzarMehr';
  src: url('./assets/fonts/AzarMehr/FD (Farsi digits)/instances/AzarMehr-FD-Regular.ttf')
    format('truetype');

  font-weight: normal;
  font-style: normal;
}

* {
  font-family: 'AzarMehr', Arial, sans-serif;
}
