$primary: #0ca8a7;
$primary-light: #0fbdbd;
$white: #ffffff;
$success: #28c76f;
$bg-top-section-default: #e9f2f6;
$input-border: #161d31;
$input-list-bg: #0fc9c9;

.darkTurquoise {
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    box-shadow: 0 0 0 30px $primary-light inset !important;
    -webkit-box-shadow: 0 0 0 30px $primary-light inset !important;
  }
  input:-webkit-autofill {
    -webkit-text-fill-color: $white !important;
  }
  input,
  textarea,
  select,
  .form-control,
  .counter-section {
    background: $primary-light !important;
    color: $input-border !important;
    border: 2px solid $primary-light !important;
  }

  input:focus,
  textarea:focus,
  select:focus,
  .form-control:focus {
    border: 2px solid $input-border !important;
    background: $primary !important;
  }
  input:focus ~ label,
  textarea:focus ~ label,
  select:focus ~ label {
    color: $input-border !important;
    background: $primary !important;
  }

  select {
    option {
      background-color: $primary;
      color: rgba($input-border, 0.75);
    }
  }

  label {
    background: none !important;
    color: rgba($input-border, 0.75) !important;
  }

  .curve-card {
    background-color: $primary;
  }
  .top-section {
    background-color: $bg-top-section-default;
    p {
      color: $primary;
    }
  }

  .message-section,
  .amount-section,
  .amount-section-default {
    background-color: $primary-light;
    color: $input-border;
  }

  .autocomplete-container {
    .suggestions-list {
      border: 1px solid $input-border;
      background-color: $input-list-bg;
      color: rgba($input-border, 0.75);

      .suggestion-item {
        background-color: $input-list-bg;
      }
      .suggestion-item:not(.last-child) {
        border-bottom: 1px solid rgba($input-border, 0.25);
        &.active,
        &:hover:not(.suggestion-title-wrapper) {
          background-color: $primary-light;
          color: $input-border;
        }
      }
      .last-child {
        background-color: $primary-light;
      }
    }
  }
}
