$primary: #6d8299;
$primary-light: #8ca1a5;
$white: #ffffff;
$secondary: rgba(255, 255, 255, 0.25);
$input-border: #fff;

.autocomplete-container {
  position: relative;
  .suggestions-list {
    z-index: 998;
    display: flex;
    flex-direction: column;
    list-style: none;
    padding-left: 0;
    border-radius: 0.5rem;
    max-height: 450px;
    height: auto;
    width: 100%;
    position: absolute;
    margin-top: 0.5rem;
    list-style-type: none;
    color: $white;

    .suggestion-item {
      padding: 0.9rem 1rem;
      line-height: 1 !important;
      &:first-child {
        border-radius: 0.5rem 0.5rem 0 0;
      }
      &:last-child {
        border-radius: 0 0 0.5rem 0.5rem;
      }
    }
    .suggestion-item:not(.last-child) {
      &:hover:not(.suggestion-title-wrapper) {
        cursor: pointer;
      }
    }
  }
}
